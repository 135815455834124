<template >
  <div
    class="loginpages"
    v-loading="loading"
    element-loading-text="正在登录..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 标题 -->
    <!--  :style="{ transform: 'scale(' + windowWidth / 1920 + ')' }" -->
    <div class="cenbox">
      <div class="title-img">
        <img class="leftimg" src="~@/assets/image/vztion/leftbg.png" alt="" />
      </div>

      <div class="lgcontent">
        <!-- 登录/注册 -->
        <div class="logres">
          <div class="login" :class="{ activelr: curren == 1 }">
            信息管理登陆
          </div>
        </div>
        <!-- 账户密码登录表单 -->
        <div class="logincontent" v-show="curren == 1">
          <el-form
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
            class="loginContainer"
          >
            <el-form-item class="username" prop="userName">
              <div class="inputbox">
                <el-input
                  v-model="loginForm.userName"
                  placeholder="请输入账号"
                  clearable
                  prefix-icon="el-icon-user-solid"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item class="password" prop="password">
              <div class="inputbox">
                <el-input
                  v-model="loginForm.password"
                  placeholder="请输入密码"
                  prefix-icon="el-icon-lock"
                  show-password
                  @keyup.enter.native="loginSubmit"
                ></el-input>
              </div>
            </el-form-item>

            <el-form-item prop="userName">
              <div class="loginsubmit" @click="loginSubmit">立即登录</div>
            </el-form-item>
          </el-form>
        </div>
        <div class="footer">
          <p>福建海峡尚合文化发展有限公司</p>
          <p style="color: #999999">技术支持：云壳科技</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      loading: false,
      windowWidth: "",
      curren: 1, //1登录 2 注册
      imgsrc: "", //验证码
      again: true,
      /* 账户密码登录表单 */
      loginForm: {
        userName: "",
        password: "",
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  // computed: {
  //   ...mapState(["cookie"]),
  // },
  watch: {},
  created() {
    this.windowWidth = window.innerWidth;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  mounted() {},

  methods: {
    refreshPageHandler() {
      window.location.reload(); // 第一种方式
    },
    getdata() {
      this.$request({
        url: "/api/News/List",
        method: "GET",
        params: {
          classid: 5,
        },
      })
        .then((res) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    loginSubmit() {
      // this.$router.replace({
      //   name: "newslist", //注意使用 params 时一定不能使用 path
      //   params: { id: 123 },
      // });
      // return;
      if (this.loginForm.userName == "" || this.loginForm.password == "") {
        this.$message.error("用户名或密码不能为空");
      } else {
        this.loading = true;
        this.$request({
          url: "/api/Login/Index",
          method: "POST",
          widthCredentials: true,
          data: {
            username: this.loginForm.userName,
            pwd: this.loginForm.password,
          },
        })
          .then((res) => {
            if (res.data.status) {
              sessionStorage.setItem("userInfo", JSON.stringify(res.data));
              sessionStorage.setItem("username", this.loginForm.userName);
              this.$store.commit("updateData", res.data.uid);
              // console.log("vuex", this.$store.state.uid);
              this.$router.replace({
                name: "newslist", //注意使用 params 时一定不能使用 path
                params: { user: this.loginForm.userName },
              });
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .finally(() => {
            this.loading = false;
          });
        // var that = this;
        // $.ajax({
        //   type: "POST",
        //   xhrFields: {
        //     withCredentials: true, //允许跨域带Cookie
        //   },
        //   url: "https://api.hxsh.ikoo.store/api/Login/Index",
        //   data: {
        //     username: this.loginForm.userName,
        //     pwd: this.loginForm.password,
        //   },
        //   success: function (data) {
        //     console.log(data);
        //     that.$store.commit("updateData", data.uid);
        //     console.log("vuex", that.$store.state.uid);
        //     that.$router.replace({
        //       name: "newslist", //注意使用 params 时一定不能使用 path
        //       params: { id: 123 },
        //     });
        //   },
        // });
      }
    },
  },
};
</script>

<style scoped>
.loginpages {
  /* box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  overflow-y: hidden;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  min-width: 1366px;
}
.lgcontent {
  /* position: absolute; */
  top: 0;
  /* left: 1200px; */
  margin-right: 40px;
  margin-left: 3%;
  margin-top: 10%;
  padding: 30px 0;
  width: 588px;
  height: 380px;
  min-width: 508px;
  min-height: 300px;
  border-radius: 12px;
  background: #f7f9ff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
}
.lgcontent > .logres {
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login,
.register {
  padding: 0 10px;
  font-size: 28px;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  letter-spacing: 0px;
  color: #979797;
  vertical-align: middle;
}
.activelr {
  position: relative;
  font-size: 36px;
  color: #4e6ef2;
}
.activelr::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 4px;
  border-radius: 4px;
  background-color: #4e6ef2;
}
.logincontent {
  margin: 50px auto 0;
}
.loginContainer {
  margin: auto;
  width: 460px;
}
.loginContainer > .username,
.loginContainer > .password {
  margin: 0 auto 35px;
  width: 440px;
  background: #ffffff;
  box-sizing: border-box;
}
.loginsubmit {
  margin: 0 auto 0;
  width: 400px;
  font-size: 26px;
  background: #4e6ef2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}
.loginsubmit:hover {
  background: #3f63f3;
}

.footer {
  margin-top: 40px;
  font-family: Inter-Regular;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
}
.footer > p {
  margin: 0;
  font-size: 13px;
  font-weight: normal;
  height: 18px;
  line-height: 18px;
}
.cenbox {
  display: flex;
}
.title-img {
  width: 60%;
  margin-left: 0;
}
.leftimg {
  min-width: 800px;
  min-height: 800px;
  width: 100%;
  height: 100%;
}
.logoimg {
  width: 152px;
  height: 150px;
}
.inputHH {
  height: 54px;
  line-height: 54px;
  font-size: 20px;
}
.inputbox >>> .el-input__inner {
  height: 54px;
  line-height: 54px;
  font-size: 20px;
}
</style>
<style>
/* .el-input__inner {
  height: 54px;
  line-height: 54px;
  font-size: 20px;
} */
</style>
