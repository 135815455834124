import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import login from '../views/login.vue'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'login',
        component: login
    },
    {
        path: '/Home',
        name: 'Home',
        meta: { title: 'Home' },
        component: () =>
            import ('@/views/Home.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: { title: 'login' },
        component: () =>
            import ('@/views/login.vue')
    },
    {
        path: '/newslist',
        name: 'newslist',
        meta: { title: '新闻列表', requireAuth: true },
        component: () =>
            import ('@/views/newsmanage/newslist.vue')
    },
    {
        path: '/newsedit',
        name: 'newsedit',
        meta: { title: '编辑新闻', requireAuth: true },
        component: () =>
            import ('@/views/newsmanage/newsedit.vue')
    },
    {
        path: '/newsadd',
        name: 'newsadd',
        meta: { title: '新增新闻', requireAuth: true },
        component: () =>
            import ('@/views/newsmanage/newsadd.vue')
    },
    {
        path: '/changepwd',
        name: 'changepwd',
        meta: { title: '修改密码', requireAuth: true },
        component: () =>
            import ('@/views/changepwd.vue')
    },
    {
        path: "*",
        name: "NotFound",
        component: () =>
            import ('@/views/404.vue'),
        // component: NotFound,
    },

]

const router = new VueRouter({
        // mode: 'history',
        routes
    })
    // router.beforeEach((to, from, next) => {
    //     if (to.path === '/' || to.path === '/login') {
    //         next();
    //     } else {
    //         alert('您还没有登录，请先登录');
    //         next('/');
    //     }
    // })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default router