<template>
  <el-breadcrumb separator="/" class="shouye" style="color: #fff">
    <img class="logoimg" src="~@/assets/image/vztion/logo.png" alt="" />
    <div class="logoname">福建海峡尚合文化发展有限公司</div>
    <div class="navbox">
      <el-breadcrumb-item style="color: #ffffff" :to="{ path: '/newslist' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{ path: item.path }"
        v-for="item in breadcrumbList"
        :key="item.path"
        >{{ item.meta.title }}</el-breadcrumb-item
      >
    </div>
  </el-breadcrumb>
</template>
 
<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      lists: [], //定义一个数组 用于接收路由信息
    };
  },

  computed: {
    //动态面包屑
    breadcrumbList() {
      return this.$route.matched;
    },
  },
};
</script>
<style  scoped>
.shouye {
  position: absolute;
  left: 20px;
  top: 15px;
  color: #fff;
  display: flex;
  z-index: 10;
}
.logoimg {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 14px;
}
.logoname {
  text-align: left;
  width: 130px;
  font-size: 16px;
  margin-bottom: 10px;
  color: #fff;
  line-height: 23px;
}
.navbox{
  margin-top: 28px;
  margin-left: 22px;
}
</style>
<style scoped>
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #fff;
}
::v-deep .el-breadcrumb__inner a,
::v-deep .el-breadcrumb__inner.is-link {
  color: #fff;
}
</style>

