import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //设置全局访问的state对象
        //设置初始属性
        uid: '',
        token: null,
        username: ''
    },
    getters: {
        //实时监听state值的变化
        getcookie(state) { //承载变化的cookie值
            return state.uid
        },
    },

    mutations: {
        //修改vuex值
        updateData(state, data) {
            state.uid = data;
        },
        updateusername(state, data) {
            state.username = data;
        },
        //token
        setToken(state, option) {
            state.token = option
        },
    },
    actions: {},
    modules: {}
})