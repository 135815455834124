import axios from 'axios'
import router from 'router'
import $router from '@/router'
import {
    Message
} from 'element-ui'

// axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
export function request(config) {
    const instance = axios.create({
        baseURL: 'https://api.hxsh.ikoo.store',
        timeout: 15000,
        withCredentials: true, // 是否允许带cookie这些
        headers: {
            // "Content-Type": "application/json",
            "accept": "text/plain",
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });

    //请求拦截器
    /* instance.interceptors.request.use(
        config => {
            Toast.loading({
                message: "加载中...",
                forbidClick: true,
            });
            return config;
        },
        error => {
            tryHideFullScreenLoading()
            Promise.reject(error)
        }
    ) */

    instance.interceptors.response.use(
        response => {
            // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
            // 否则的话抛出错误
            // console.log('111', response)
            if (response.data.status === 200) {
                return Promise.resolve(response.data);
            } else {
                return Promise.resolve(response);
                return Promise.reject(response);
            }
        },
        error => {
            if (error.message.includes('timeout')) {
                console.log("错误回调", error);
                Message({
                    message: '请求超时',
                    duration: 2000,
                    forbidClick: true
                });
                return Promise.reject(error);
            }
            if (error.response.status) {
                switch (error.response.status) {
                    case 401:
                        Message({
                            message: '登录已过期,请重新登录',
                            duration: 2000,
                            forbidClick: true
                        });
                        // router.replace({
                        //     path: '/',
                        // });
                        $router.push({ path: '/' }); //返回登录页
                        sessionStorage.clear(); //清除缓存
                        break;
                    case 403:
                        Message({
                            message: error.response.data.error.message,
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 404:
                        Message({
                            message: '网络请求不存在',
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 405:
                        Message({
                            message: 'Method not allowed',
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    case 500:
                        Message({
                            message: '服务器异常',
                            duration: 2000,
                            forbidClick: true
                        });
                        break;
                    default:
                        Message({
                            message: error.response.data.error.message,
                            duration: 1500,
                            forbidClick: true
                        });
                }
                return Promise.reject(error.response.data);
            }
        }
    );
    return instance(config)
}