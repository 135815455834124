import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import db from './network/sessionStorage'
import $ from 'jquery'
import 'moment/locale/zh-cn' //中文
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/icon.css'; //element-ui 图标库
import echarts from 'echarts'
import {
    request
} from './network/request.js'
import cookie from './util/cookie'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
Vue.use(iView)
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {
        if (JSON.parse(sessionStorage.getItem("userInfo"))) {
            next();
        } else {
            next({
                path: "/" //指向为你的登录界面
            });
        }
    } else {
        next();
    }

    // if (to.fullPath === "/SignIn") {
    //     if (JSON.parse(localStorage.getItem("islogin"))) {
    //         next({
    //             path: from.fullPath
    //         });
    //     } else {
    //         next();
    //     }
    // }
});

Vue.prototype.$db = db
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.prototype.$request = request
Vue.prototype.cookie = cookie
    // Vue.prototype.baseURL = "http://192.168.1.245:11000"
    // Vue.prototype.configBaseUrl = "http://112.111.62.152:9072"

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')