<template>
  <div>
    <el-table :data="tableData">
      <el-table-column prop="date" label="日期" width="140"> </el-table-column>
      <el-table-column prop="name" label="姓名" width="120"> </el-table-column>
      <el-table-column prop="address" label="地址"> </el-table-column>
    </el-table>
  </div>
</template>


<script>
import navbox from "../components/navhead.vue";
export default {
  components: {
    navbox,
  },
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
    };
    return {
      tableData: Array(10).fill(item),
    };
  },
  methods: {
    clickMenu(item) {
      this.$router.push(
        //页面跳转
        {
          name: demo2,
        }
      );
    },
  },
};
</script>

<style >
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  height: auto;
  color: #000;
  overflow: hidden;
}
.el-aside::-webkit-scrollbar {
  display: none;
}
</style>