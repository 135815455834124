<template>
  <div>
    <el-container style="height: auto" v-if="$route.meta.requireAuth">
      <!-- v-if="!(path === '/')" -->
      <el-header
        style="
          text-align: right;
          font-size: 12px;
          background-color: #0d3661;
          color: #fff;
          height: 66px;
        "
      >
        <navbox></navbox>
        <span class="mouse">{{ username }}</span>
        <!-- <el-dropdown>
          <i
            class="el-icon-setting"
            style="margin-right: 15px; color: #fff; margin-top: 15px"
          ></i>
          
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="exit">退出</el-dropdown-item>
            <el-dropdown-item @click.native="gotochange(1)">修改密码</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <!-- <span>王小虎</span> -->
      </el-header>
      <el-container>
        <el-aside
          width="200px"
          style="
            background-color: #0d3661;
            height: 100vh;
            z-index: 0;
            margin-top: -66px;
          "
        >
          <el-menu
            :default-active="$route.path"
            :router="true"
            style="background-color: #0d3661; margin-top: 66px"
          >
            <el-submenu index="1">
              <template slot="title"
                ><i class="el-icon-menu"></i>新闻管理</template
              >
              <el-menu-item-group>
                <el-menu-item index="/newslist">新闻列表</el-menu-item>
                <el-menu-item index="/newsadd">新闻添加</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title"
                ><i class="el-icon-s-unfold"></i>系统设置</template
              >
              <el-menu-item-group>
                <el-menu-item index="/changepwd">修改密码</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3" @click.native="exit">
              <template slot="title"
                ><i class="el-icon-setting"></i>退出登录</template
              >
            </el-submenu>
            <!-- <el-submenu index="3">
              <template slot="title"
                ><i class="el-icon-edit"></i>新闻修改</template
              >
              <el-menu-item-group>
                <el-menu-item index="/newsedit">编辑新闻</el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
          </el-menu>
          <div class="bottomtxt">技术支持 ：云壳科技</div>
        </el-aside>

        <el-main class="mainbox">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <div v-else><router-view /></div>
  </div>
</template>

<script>
import navbox from "./components/navhead.vue";
export default {
  components: {
    navbox,
  },
  data() {
    return {
      path: "",
      loginname: "",
      username: "",
    };
  },
  mounted() {
    this.path = this.$route.path;
    this.loginname = this.cookie.getCookie("LoginName");
    this.username = sessionStorage.getItem("username");
  },
  created() {
    // if (this.$route.params.user !== "") {
    //   this.username = this.$route.params.user;
    // } else {
    //   this.username = sessionStorage.getItem("username");
    // }
    this.username = sessionStorage.getItem("username");
    setInterval((res) => {
      this.username = sessionStorage.getItem("username");
    }, 1000);
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
    },
  },
  methods: {
    gotochange(val) {
      this.$router.push({
        path: "/changepwd",
        query: {
          id: val,
        },
      });
    },
    exit() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.removeItem("userInfo");
          this.$router.replace("/");
          this.$message({
            type: "success",
            message: "您已退出登录!",
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  background: #f4f4f4 100% 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.mainbox {
  overflow-y: auto;
  height: 90vh;
}
.mouse {
  cursor: pointer;
  color: #fff;
}
.topnav {
  height: 60px;
  // color: #FFFFFFB3;
  // background-color: #20022A;
  font-size: 16px;
  text-align: center;
  padding-top: 18px;
  color: #fff;
}
</style>
<style scoped>
::v-deep .el-menu-item-group__title {
  padding: 0;
}
::v-deep .el-header {
  background-color: #0d3661;
  color: #fff;
  line-height: 60px;
}
::v-deep .el-submenu .el-menu-item:hover {
  background-color: #1d66b4;
  color: #fff;
}

::v-deep .el-submenu,
::v-deep .el-menu-item,
::v-deep .el-submenu__title {
  background-color: #0d3661;
  color: #fff;
}

::v-deep .el-submenu__title:hover {
  background-color: #1d66b4;
  color: #fff;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .el-dropdown {
  line-height: 70px;
}
.bottomtxt {
  color: #eee;
  position: absolute;
  bottom: 30px;
  left: 35px;
  right: 0;
  margin: 0 auto;
}
</style>
